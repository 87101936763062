import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import { Row, Col } from 'antd';
import AppHeader from './utils/app/appHeader';


function AppLayout({ profileData, initialRefetch }) {

    useEffect(() => {
        initialRefetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="service-view error-page">
            <Row>
                <Col md={24} xs={24}>
                    <AppHeader profileData={profileData} />
                </Col>
                <Col md={24} xs={24}>
                    <Outlet />
                </Col>
            </Row>
        </div>
    );
}

export default AppLayout;