import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthHeader = () => {

    let navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("token")) {
            navigate("/dashboard")
        }
    })

    return (
        <header className="auth-header">
        </header>
    )
}
export default AuthHeader;
