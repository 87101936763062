import { message } from "antd";
import axios from "axios";

const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;
const TOKEN = localStorage.getItem("token");
const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;

const client = axios.create({
  baseURL: REACT_APP_BASE_API,
  timeout: TIMEOUT,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${TOKEN}`,
  },
});

client.interceptors.request.use(function (config) {
  return config;
});
client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data) {
      message.error(error?.response?.data?.message)
    } else if (error.response.status !== 200) {
      message.error(error.response.data.data);
    } else {
      console.error(error);
    }
    return Promise.reject(error);
  }
);

const ProfileDetails = async () => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.get("/profileDetails");
  return data;
};

const UpdateProfileDetails = async (formData) => {
  const { data } = await client.put("/editProfile", formData);
  return data;
}

export {
  ProfileDetails,
  UpdateProfileDetails
};
