import { useMutation, useQuery } from "react-query";
import * as csv from "services/csvServices/csvService";

export const useDownloadCsv = (uri, searchKey, page, size) => {
  return useQuery(["Downcsv", uri], () => csv.DownloadCSV(uri,searchKey, page, size),
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );
};

export const usePostDownloadCsv = (uri, formData) => {
  return useMutation(() => csv.PostDownload(uri, formData));
};