import axios from "axios";
import { message } from "antd";

const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;
const TOKEN = localStorage.getItem("token");
const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;

const client = axios.create({
  baseURL: REACT_APP_BASE_API,
  timeout: TIMEOUT,
  headers: {
    "content-type": "application/json",
    Authorization: `Bearer ${TOKEN}`,
  },
});

client.interceptors.request.use(function (config) {
  return config;
});
client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data) {
      message.error(error?.response?.data?.message)
    } else if (error.response.status !== 200) {
      message.error(error.response.data.data);
    } else {
      console.error(error);
    }
    return Promise.reject(error);
  }
);

const DashboardCountData = async () => {
  if (localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.get("/employee_dashboard");
  return data;
};

const DashboardDropdownData = async () => {
  if (localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.get("/employee/dropdown/dashboard");
  return data;
};

const DashboardMap = async (id) => {
  if (localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.get(`/dashboard/getSitesAndFloors?employeeId=${id || ""}`);
  return data;
};

const EmployeeListByGatewaySerialNumber = async (formData) => {
  const { data } = await client.post("/dashboard/employeeList/gateway", formData);
  return data;
}

const AlertData = async () => {
  if (localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.get("/unauthorized_alert");
  return data;
};

const RevertAlertData = async (statusId) => {
  const { data } = await client.get(`/unauthorized_alert/revert/${statusId}`);
  return data;
}

const LocationCount = async () => {
  const { data } = await client.get(`/dashboard/zoneEmployeeCount`);
  return data;
}

export {
  DashboardCountData,
  DashboardDropdownData,
  DashboardMap,
  EmployeeListByGatewaySerialNumber,
  AlertData,
  RevertAlertData,
  LocationCount
};