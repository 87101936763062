import { useQuery, useMutation } from "react-query";
import * as dashboard from "services/dashboard/dashboard";

export const useDashboardCount = () => {
  return useQuery("dashboardQuery", () => dashboard.DashboardCountData(), {
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    refetchInterval: 5000,
  });
};

export const useDashboardDropdown = () => {
  return useQuery("dashboardDropDownQuery", () => dashboard.DashboardDropdownData(), {
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  }
  );
};

export const useDashboardmap = (id) => {
  return useQuery(["dashboardMap", id], () => dashboard.DashboardMap(id), {
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    refetchInterval: 5000,
  });
};

export const useEmployeeListByGatewaySerialNumber = () => {
  return useMutation((formData) => dashboard.EmployeeListByGatewaySerialNumber(formData));
}

export const useAlertData = () => {
  return useQuery("AlertData", () => dashboard.AlertData(), {
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    refetchInterval: 5000,
  }
  );
};

export const useRevertAlert = (statusId) => {
  return useQuery(["revertAlert", statusId], () => dashboard.RevertAlertData(statusId), {
    retry: false,
    enabled: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });
}

export const useLocationCount = () => {
  return useQuery("LocationCount", () => dashboard.LocationCount(), {
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    refetchInterval: 5000,
  }
  );
};