import React, { Fragment, useEffect } from "react";
import { useAwsImage } from "hooks/awsImage/AwsImage";

const PrivateImage = ({ className, privateUrl, imgType, skeleton, onClick }) => {
    const { data, refetch, isLoading } = useAwsImage(privateUrl, imgType);

    useEffect(() => {
        if (privateUrl !== undefined) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privateUrl]);

    return (
        <Fragment>
            {
                !isLoading && privateUrl !== undefined && data !== undefined ?
                    <img className={className} src={data} alt="...." onClick={onClick} /> : <div className={skeleton} ></div>
            }
        </Fragment>
    )
};

export default PrivateImage;