import Image from "./Image";
import moment from "moment";

export const statusColor = (status) => {
  if (status) {
    return "active-btn";
  } else {
    return "inactive-btn";
  }
};

export const changeDateFormat = (date) => moment(date).format("DD-MM-YYYY");

export const changeMonthFormat = (date) => moment(date).format("MM");
export const changeYearFormat = (date) => moment(date).format("YYYY");


export const singaporeTime = (val, format = 'DD-MM-YYYY HH:mm:ss') => {
  if (val) {
    const utcTime = moment.utc(val, format);
    const singaporeTime = utcTime.clone().utcOffset('+08:00');
    const formattedTime = singaporeTime.format('DD-MM-YYYY HH:mm:ss');
    return formattedTime;
  }
}

export const singaporeTimeOnly = (val) => {
  if (val) {
    const utcTime = moment.utc(val, 'DD-MM-YYYY HH:mm:ss');
    const singaporeTime = utcTime.clone().utcOffset('+08:00');
    const formattedTime = singaporeTime.format('HH:mm:ss');
    return formattedTime;
  }
}

export const singaporeTimeWithoutSeconds = (val, format = 'DD-MM-YYYY HH:mm:ss') => {
  if (val) {
    const utcTime = moment.utc(val, format);
    const singaporeTime = utcTime.clone().utcOffset('+08:00');
    const formattedTime = singaporeTime.format('DD-MM-YYYY HH:mm');
    return formattedTime;
  }
}

export const isSameDate = (startDate,endDate) => {
  return moment(startDate,'DD-MM-YYYY HH:mm:ss').isSame(moment(endDate,'DD-MM-YYYY HH:mm:ss'),'day');
}

export const getDateOnly = (date) => {
  return moment(date, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY");
}

export const removeSecondsFromColonSeperatedString = (value) => {
  let splitString = value.split(":");
  splitString.pop(); 

  let resultString = splitString.join(":");
  return resultString;
}

export const eventColor = (event) => {
  if (event) {
    return "event-start";
  } else {
    return "event-stop";
  }
};

export const iconColor = (event) => {
  if (event === Number(0)) {
    return <Image name="gray.gif" />;
  } if (event <= Number(3)) {
    return <Image name="yellow.gif" />;
  } else if (event <= Number(5)) {
    return <Image name="green.gif" />;
  } else if (event <= Number(7)) {
    return <Image name="red.gif" />
  } else if (event > Number(7)){
    return <Image name="blue.gif" />;
  }
};


export const iconCount = (event) => {

  if (event === Number(0)) {
    return "count-gray";
  } else if (event <= Number(3)) {
    return "count-yellow";
  } else if (event <= Number(5)) {
    return "count-green";
  } else if (event <= Number(7)) {
    return "count-red";
  } else if (event > Number(7)) {
    return "count-blue";
  }
};

export const exportToJson = (dataCsv, fileName) => {
  downloadFile({
    data: dataCsv,
    fileName: fileName,
    fileType: 'text/csv',
  })
}

export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType })
  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

//image type for aws service
export const profile_image_type = 1;
export const floor_image_type = 2;
export const admin_image_type = 3;

//report types for employee
export const attendance_type = 1;
export const absentee_type = 2;

//reason types for unauthorised employee
export const all_reason = 0;
export const unauthorised_entry = 1;
export const not_in_zone_at_working_hours = 2;
export const working_beyond_work_permit_hours = 3;