import { message } from "antd";
import axios from "axios";

const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;
const TOKEN = localStorage.getItem("token");
const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;

const client = axios.create({
  baseURL: REACT_APP_BASE_API,
  timeout: TIMEOUT,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${TOKEN}`,
  },
});

client.interceptors.request.use(function (config) {
  return config;
});
client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data) {
      message.error(error?.response?.data?.message);
    } else if (error.response.status !== 200) {
      message.error(error.response.data.data);
    } else {
      console.error(error);
    }
    return Promise.reject(error);
  }
);

const EmployeeListing = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.post("/getEmployeeList", formData);
  return data;
};

const AddEmployee = async (formData) => {
  const { data } = await client.post("/addEmployee", formData);
  return data;
};

const EditEmployee = async (formData) => {
  const { data } = await client.put("/editEmployee", formData);
  return data;
};

const deleteEmployee = async (id) => {
  const { data } = await client.delete(`/deleteEmployee/${id}`);
  return data;
};

const ZoneListing = async () => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.get(`/zone/dropdown`);
  return data;
};

const getZoneForEmployee = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.post("/zone_list_for_employee", formData);
  return data;
};

const ActiveOrInactive = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.put("/employee/activeOrInactive", formData);
  return data;
}

const DepartmentListing = async () => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.get(`/department/dropdown`);
  return data;
};

const DropdownShift = async () => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.get(`/all_shifts`);
  return data;
};

const EmployeeCardList = async () => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.get(`/beaconList/dropdown`);
  return data;
};

const EmployeeCardListForEdit = async (id) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.get(`/get_beacon_for_edit/${id}`);
  return data;
};

const zoneListForEmployee = async (id) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.get(`/zone_list_for_employee/${id}`);
  return data;
};

export {
  EmployeeListing,
  ZoneListing,
  DepartmentListing,
  AddEmployee,
  EditEmployee,
  DropdownShift,
  deleteEmployee,
  zoneListForEmployee,
  EmployeeCardListForEdit,
  getZoneForEmployee,
  EmployeeCardList,
  ActiveOrInactive
};
