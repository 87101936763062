import { useMutation, useQuery } from "react-query";
import * as employee from "services/configuration/employee/employee";

export const useEmployeeListing = () => {
  return useMutation((formData) => employee.EmployeeListing(formData));
};

export const useAddEmployee = () => {
  return useMutation((formData) => employee.AddEmployee(formData));
};

export const useEditEmployee = () => {
  return useMutation((formData) => employee.EditEmployee(formData));
};

export const useDeleteEmployee = () => {
  return useMutation((formData) => employee.deleteEmployee(formData));
};
export const useGetZoneListForEmployee = () => {
  return useMutation((formData) => employee.getZoneForEmployee(formData));
};

export const useActiveOrInactive = () => {
  return useMutation((formData) => employee.ActiveOrInactive(formData));
};

export const useZoneList = () => {
  return useQuery("ZoneList", () => employee.ZoneListing(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  });
};

export const useDepartmentList = () => {
  return useQuery("DepartmentList", () => employee.DepartmentListing(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  });
};

export const useShiftDropdown = () => {
  return useQuery("dropDownShift", () => employee.DropdownShift(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  });
};

export const useCardList = () => {
  return useQuery("CardList", () => employee.EmployeeCardList(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  });
};

export const useCardListForEdit = (id) => {
  return useQuery(["CardListForEdit", id], () => employee.EmployeeCardListForEdit(id),
    {
      retry: false,
      enabled: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useZoneListEmployee = (id) => {
  return useQuery(["zoneListForEmployee", id], () => employee.zoneListForEmployee(id),
    {
      retry: false,
      enabled: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    }
  );
};